.login-container {
  /*   background: url('https://admin-order.s3.ap-northeast-1.wasabisys.com/2021/11/03/3eb89c6b-8d3c-4fed-b4b8-e7e5aefb7888/627%20%5BConverted%5D-01%201.png')
    no-repeat;
  background-size: cover; */

  width: 100%;
  height: 100vh;
  .login-form-content {
    // background-color: gray;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10%;
    img {
      display: block;
      width: 35%;
    }
    h1 {
      font-size: 28px;
      color: #445268;
      padding: 25px 0 15px 0;
    }
    .login-form-content-btn {
      background-color: #256bfe;
      width: 100%;
      font-size: 20px;
      height: 40px;
      font-weight: 700;
      border-radius: 15px;
      box-shadow: 0px 0px 25px rgba(37, 107, 254, 0.29);
    }
  }
  .login-banner-pc {
    padding: 40px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, rgba(36, 111, 255, 1), rgba(37, 107, 254, 0.5));
  }
  .login-banner-mobile {
    display: none;
  }
}
@media screen and(max-width:768px) {
  .login-container {
    .login-banner-pc {
      display: none;
    }
    .login-banner-mobile {
      display: block;
      padding: 50px 10px 10px 10px;
    }
  }
}

.login {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  // background: url('https://admin-order.s3.ap-northeast-1.wasabisys.com/2021/11/03/3eb89c6b-8d3c-4fed-b4b8-e7e5aefb7888/627%20%5BConverted%5D-01%201.png') no-repeat center center fixed;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  img {
    width: 100vw;
    height: 100vh;
  }

  &-form {
    width: 350px;
    position: absolute;
    top: 100px;
    left: 100px;

    h2 {
      font-weight: 700;
    }

    .login-button {
      background-color: black;
      color: #fff;
      border: none;
      transition: 0.3s;
      width: 100%;
      border-radius: 5px;
      &:hover {
        background-color: lighten($color: #000000, $amount: 5);
      }
    }
  }

  &-footer {
    position: absolute;
    bottom: 20px;
    left: 35px;
    font-size: 16px;
    font-weight: 700;
  }
}
