@mixin button-background($color) {
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  border-radius: 5px !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
  }
}
.btn-create {
    min-width: 130px;
    @include button-background(#FF9C64);
}