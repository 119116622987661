.orders_manager {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.orders_manager_content_row_col_filter_checkbox {
  width: 100%;
}
.orders_manager_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
  border-bottom: 1px solid rgb(199, 185, 185);
}
.orders_manager_header_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.orders_manager_header_row_col {
  width: 100%;
  margin-bottom: 1rem;
}
.orders_manager_header_row_col_parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.orders_manager_header_row_col_parent > div {
  color: rgb(185, 174, 174);
  font-size: 1rem;
  // font-weight: 600;
}
.orders_manager_header_row_col_parent_icon {
  margin-right: 0.5rem;
}
.orders_manager_header_row_col_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.orders_manager_header_row_col_right_exports {
  display: flex;
  cursor: pointer;
  margin-right: 1.5rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.orders_manager_header_row_col_right_exports > div {
  color: #1890ff;
  font-size: 1rem;
}
.orders_manager_header_row_col_right_exports_icon {
  margin-right: 0.5rem;
}
.import_orders {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.5rem;
  flex-direction: column;
  width: 100%;
}
.import_orders_title {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(201, 184, 184);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.import_orders_title_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.import_orders_title_right {
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.import_orders_title_left > div {
  font-size: 1rem;
  color: black;
}
.import_orders_title_right > div {
  font-size: 1rem;
  color: black;
}
.import_orders_title_left_icon {
  margin-right: 0.5rem;
}
.import_orders_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}
.import_orders_content_step1 {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.hover {
  color: #1890ff;
  cursor: pointer;
}
.import_orders_content_select {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.import_orders_content_select_child {
  width: 100%;
}
.import_orders_content > div {
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.import_orders_content_bottom {
  display: flex;
  justify-content: center;
  border: 1px solid rgb(209, 196, 196);
  cursor: pointer;
  background-color: #fafafa;
  border-style: dotted;
  padding: 1.5rem 1rem;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.import_orders_content_bottom_upload {
  margin-bottom: 1rem;
}
.import_orders_content_button {
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.import_orders_content_button_left {
  margin-right: 1.5rem;
}
.orders_manager_content {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.orders_manager_content_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.orders_manager_content_row_col {
  margin-bottom: 1rem;
  width: 100%;
}
.orders_manager_content_row_col_search {
  width: 100%;
}
.orders_manager_content_row_col_date {
  width: 100%;
}
.orders_manager_content_row_col_filter {
  display: flex;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  align-items: center;
  border: 1px solid rgb(218, 203, 203);
  cursor: pointer;
  width: 100%;
}
.orders_manager_content_row_col_filter_check {
  display: flex;
  justify-content: flex-start;
  padding: 0.25rem 0.5rem;
  align-items: center;
  border: 1px solid rgb(218, 203, 203);
  cursor: pointer;
  width: 100%;
}
.orders_manager_content_row_col_filter_icon {
  margin-right: 0.5rem;
}
.orders_manager_content_row_col_filter:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
}
.orders_manager_tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.orders_manager_tabs_table {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: white;
  max-width: 100%;
  border: 1px solid rgb(214, 200, 200);
  overflow: auto;
}
.orders_manager_tabs_table_child {
  width: 100%;
  background-color: white;
}
.sort {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  flex-direction: column;
}
.sort > div {
  color: #1890ff;
}
.note_check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: grey;
}
.connect_button {
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.api_key {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.question_icon {
  background-color: #000000;
  color: white;
  border-radius: 50%;
  border: none;
}

.api_key_col {
  width: 100%;
}
.api_key_col_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.api_key_col_icon {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  padding-top: 2rem;
  background-color: red;
}
.tutorial {
  cursor: pointer;
}

.key_tutorial {
  display: flex;
  padding-bottom: 1.75rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.key_tutorial_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.modal_key {
  width: 100%;
  max-width: 100%;
  overflow: auto;
}
.key_tutorial_row_col {
  width: 100%;
  margin-top: 1.75rem;
}
.key_tutorial_row_col_image {
  width: 100%;
  height: 100%;
}
.key_tutorial_row_col_image_parent {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
}
.popover_child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
}
.popover_child > div {
  margin-right: 0.5rem;
  // color: black;
  cursor: pointer;
  // font-size: 1rem;
}
.popover_child:hover {
  // background-color: rgb(233, 225, 225);
}
.popover_parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.5rem;
  width: 100%;
  flex-direction: column;
}
// .popover_child_title {
//   color: black;
//   font-size: 1rem;
// }
.modal_edit_site {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.modal_edit_site_row {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.modal_edit_site_row_col {
  width: 100%;
  margin-bottom: 1rem;
}

.loading {
  display: flex;
  visibility: visible;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
}
.loading_active {
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
}
.spinner-3 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #25b09b);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

.table-container {
  width: calc(100% - 10px) !important;
  box-shadow: 0px 0px 10px rgba(37, 107, 254, 0.29);
  border-radius: 15px;
  background: #ffffff;
  width: 100%;
  margin: 5px;
  overflow: hidden;
}

.table-order {
  width: 100%;
}

.table-order th {
  background-color: white !important;
  border-bottom: 1px solid #256bfe !important;
}

.table-order th::before {
  width: 0px !important;
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
