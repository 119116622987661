@mixin button-background($color) {
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  border-radius: 5px !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
  }
}
.button {
  &--orange {
    @include button-background(#ff871d);
  }
}
.body-order {
  // background-color: white;
  // padding: 16px;
  // margin: 15px 15px;
  // border-radius: 5px;
  .body-order-modal {
    p {
      font-size: 30px;
      color: #223b4f;
    }
  }
  .body-order-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    .body-order-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .body-order-header-list-text {
      font-size: 20px;
      padding-right: 10px;
      color: #223b4f;
      font-weight: 700;
    }
    .button-create-order {
      background-color: #246fff;
      color: white;
      border-radius: 5px;
      font-weight: 700;
    }
  }
  .body-order-filter {
    padding: 15px 0;
  }
  .body-order-delete-filter {
    min-height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
  }
  .table-order-child-row {
    background-color: #d8e5ff;
  }
}
