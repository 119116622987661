.page-title-wrap {
  padding-bottom: 15px;
  z-index: 1000;
  margin-bottom: 25px;
  border-bottom: 0.75px solid rgba(0, 0, 0, 0.35);

  .page-title {
    font-weight: 700;
    font-size: 20px;
    color: rgb(34, 59, 79);
  }
}
