* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', Helvetica, Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* c


/* button */
.page-title {
  font-weight: 700;
  padding-bottom: 15px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  border-bottom: 0.75px solid rgba(0, 0, 0, 0.35);
}

/* custom item img to component upload antd */
.ant-upload-list-item-thumbnail > img {
  object-fit: contain !important;
}

/* custom background menu child  */
.ant-menu-sub {
  background-color: unset !important;
}

/* custom menu item active */
.ant-menu-item-selected {
  background-color: #82e7f4 !important;
}
.ant-menu-item-selected > .anticon {
  color: #445268 !important;
  font-size: 18px !important;
}
.ant-menu-item-selected > .ant-menu-title-content > a {
  color: #445268 !important;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #445268 !important;
}
.ant-menu.ant-menu-inline-collapsed .anticon {
  font-size: 20px !important;
}

/* custom border modal confirm remove product */

.ant-modal-title {
  font-weight: bold !important;
}

/* custom popover antd */
.ant-popover.ant-popconfirm {
  z-index: 10003 !important;
}
/* custom popover antd */

/* custom notification antd */
.ant-notification {
  z-index: 10002 !important;
}
/* custom notification antd */

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0 !important;
}
.ant-table-cell {
  text-align: center !important;
}

.ant-form-item-label > label {
  font-size: 16px !important;
  font-weight: bold !important;
}

.ant-row.ant-form-item {
  margin-bottom: 10px;
}

.ant-table-container {
  border: 1px solid #d8d8d8 !important;
}

.ant-tooltip-inner a {
  color: white;
}
.ant-tooltip-inner a:hover {
  color: unset;
}

.btn-export {
  background-color: #79cf50 !important;
  border-color: #79cf50 !important;
}

.row-order-ecommerce-issue {
  background-color: #ffe5e5;
}

.ant-table-body::-webkit-scrollbar {
  height: 15px;
  width: 10px;
}
.ant-table-body::-webkit-scrollbar-track {
  background-color: #fff;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background: #13d8e5;
  border-radius: 3px;
}

.ant-table-sticky-scroll {
  height: 7px !important;
  opacity: 1 !important;
}

.ant-table-sticky-scroll .ant-table-sticky-scroll-bar {
  background-color: #13d8e5;
  height: 15px;
}

.table-disable-hover .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: unset;
}
.bg-blue {
  background-color: #e6fffc;
}
.bg-orange .ant-table-cell {
  background-color: #fd9d9d !important;
}
