@mixin cell-table-background($color) {
  background-color: $color !important;
  transition: 0.3s;
  &:hover {
    background-color: darken($color, 5%) !important;
  }
}
$table-header-bgColor: #f3f3f3;
@mixin button-background($color) {
  min-width: 100px !important;
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
    border-color: lighten($color, 8%) !important;
  }
}
.dropdown-wrapper {
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $table-header-bgColor;
  transition: 0.3s;
  &:hover {
    background-color: darken($table-header-bgColor, 5%);
  }
}

.btn--clear {
  @include button-background(#ff7089);
}

.WAITING {
  @include cell-table-background(rgba(255, 192, 134, 0.75));
}
.PROCESSING {
  @include cell-table-background(#82e7f4);
}
.SHIPPING {
  @include cell-table-background(#82e7f4);
}
.COMPLETE {
  @include cell-table-background(rgba(130, 248, 151, 0.75));
}
.REFUND {
  @include cell-table-background(#eb7300);
}
// .REPORT {
//   @include cell-table-background(#d63f3f);
// }
// .CANCEL {
//   @include cell-table-background(#d63f3f);
// }
