.dashboard {
  &-content {
    padding: 14px;
    height: 100%;
    /* height: calc(100vh- 160px); */
    &--slogan {
      box-shadow: 0px 0px 10px #3ad0e3;
      padding: 10px;
      padding-bottom: 0px;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 500;
    }

    &--statis {
      color: black;
      text-align: center;
      background: rgba(130, 231, 244, 0.25);
      border: 2px solid #82e7f4;
      border-radius: 5px;
      width: 100%;
      font-size: 18px;
      span {
        font-weight: 500;
      }
    }

    &--chart {
      background: #ffffff;
      border: 1px solid #82e7f4;
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(58, 208, 227, 0.5);
      border-radius: 5px;
      padding: 10px;
    }

    &--list-product {
      &::-webkit-scrollbar {
        margin-left: 10px;
        width: 8.5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 5px;
        &:hover {
          background: #a8a8a8;
        }
      }
    }

    &--product {
      background: #ffffff;
      border: 1px solid #82e7f4;
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(58, 208, 227, 0.5);
      border-radius: 5px;
      padding: 10px;
      height: 100%;

      &-item {
        &-image img {
          width: 60px !important;
          height: 60px !important;
        }
        &-content {
          padding: 0 8px;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        &-price {
          display: flex !important;
          justify-content: end;
          padding-right: 10px;
          font-weight: 500;
        }
        padding: 5px;
        background: #ffffff;
        border: 1px solid rgba(169, 169, 169, 0.5);
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 8px;
        margin-right: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
