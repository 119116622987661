@mixin button-background($color) {
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
        border-radius: 5px !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
  }
}
.btn {
    @include button-background(#377BFF);
}