@mixin button-background($color) {
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  border-radius: 5px !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
  }
}
.body-import {
  .body-import-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    .body-import-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .body-import-header-list-text {
      font-size: 20px;
      padding-right: 10px;
      font-weight: bold;
    }
  }
  .body-import-content {
    width: 100%;
  }
}
.btn-add {
  @include button-background(#3b7eff);
}
.import-warehouse-dropdown {
  height: 200px !important;
}
