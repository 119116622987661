.site-layout-background {
  height: 100vh;
  background-color: #f0feff !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  .menu-left {
    background-color: #f0feff !important;
    &::-webkit-scrollbar {
      width: 8.5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 5px;
      &:hover {
        background: #a8a8a8;
      }
    }
  }
}
.header_navbar_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_left_parent {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
}
.navbar_left_parent > img {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}
.navbar_left {
  display: flex;
  margin: 0.5rem 1rem;
  justify-content: flex-start;
  align-items: center;
}
.navbar_left > img {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}
.header_navbar_left_name {
  margin: 0 1rem;
}
.header_navbar_left_icon {
  font-size: 1.4rem;
  font-weight: 600;
}
.navbar_right_select {
  margin-right: 1rem;
}
.navbar_right_left_name {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.navbar_right_left_name > div {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 2.5rem;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.user_information {
  display: flex;
  background-color: #fff;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
  border: 1px solid rgb(230, 218, 218);
  padding-bottom: 0.25rem;
  width: 15rem;
  border-radius: 15px;
  flex-direction: column;
  color: #565656;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.user_information_link {
  display: flex;
  // margin-top: 0.5rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 0;
  color: #565656;
  // padding-bottom: 0.5rem;
  // border-bottom: 1px solid rgb(230, 218, 218);
  width: 100%;
}
.user_information > div {
  display: flex;
  // margin-top: 0.5rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 0;
  // padding-bottom: 0.5rem;
  // border-bottom: 1px solid rgb(230, 218, 218);
  width: 100%;
}

.user_information_link:hover {
  background-color: rgb(236, 236, 236);
}
.information_user_modal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.information_user_modal > div {
  margin-bottom: 0.5rem;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 600;
}
.user_information > div:hover {
  transition: all 0.3s;
  background-color: rgb(236, 236, 236);
}
.user_information > div > div {
  color: black;
  font-weight: 600;
}
.navbar_right {
  display: flex;
  // margin: 0.5rem 1rem 0.5rem 0rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.navbar_right > div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // width: 100%;
  // width: 5rem;
}
.navbar_right .navbar_notification {
  color: rgb(253, 170, 62);
  margin-right: 10px;
  padding-right: 15px;
  border-right: solid 1px rgb(253, 170, 62);
}

.navbar_right_fix > div {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.navbar_right > div {
  cursor: pointer;
}
.header_navbar_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header_navbar_right_left {
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header_navbar_right_img {
  width: 2rem;
  height: 2rem;
  // margin-left: 0.5rem;
  object-fit: contain;
}
.header_navbar {
  display: flex;
  box-shadow: inset 0 0 0.2rem gray;
  border-bottom: 1px solid grey;
  justify-content: space-between;
  align-items: center;
}
.navbar {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.bell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 25px rgba(37, 107, 254, 0.29);
  border-radius: 15px;
  overflow: hidden;

  .bell-setting {
    margin: 10px 20px;
    padding-bottom: 10px;
    max-width: 320px;
  }
}

.news_show {
  padding: 20px;
  max-width: 400px;
  min-width: 350px;
  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background-color: #12d8e5;
  }
}
.upload-image [class='ant-col ant-form-item-label'] {
  padding-bottom: 3px !important;
}
