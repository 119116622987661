@mixin button-background($color) {
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
  }
}
.body-export {
  // background-color: white;
  // padding: 16px;
  // margin: 15px 15px;
  // border-radius: 5px;
  .body-export-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    .body-export-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .body-export-header-list-text {
      font-size: 20px;
      padding-right: 10px;
      font-weight: bold;
    }
  }
  .body-export-content {
    width: 100%;
  }
}
.export-warehouse-dropdown {
  height: 200px !important;
}
