.package-tracking-item {
  margin-top: 20px;
  .package-tracking-item-quantity {
    font-size: 12px;
    padding: 5px;
    font-weight: 700;
    width: 25px;
    height: 20px;
    background-color: #ecf0f1;
    color: #bdc3c7;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 0 5px 0;
  }
  .package-tracking-item-title {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  // .package-tracking-item-descript{
  //   font-size: 16px;
  //   overflow: hidden;
  //  text-overflow: ellipsis;
  //  display: -webkit-box;
  //  -webkit-line-clamp: 2; /* number of lines to show */
  //          line-clamp: 2;
  //  -webkit-box-orient: vertical;
  // }
}
