@mixin button-background($color) {
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  border-radius: 5px !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
  }
}
.button {
  &--green {
    @include button-background(#ff871d);
  }
}
.body-debit {
  // background-color: white;
  // padding: 16px;
  // margin: 15px 15px;
  // border-radius: 5px;
  .body-debit-modal {
    p {
      font-size: 30px;
      color: #223b4f;
    }
  }
  .body-debit-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    .body-debit-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: space-between;
    }
    .body-debit-header-list-text {
      font-size: 20px;
      padding-right: 10px;
      color: #223b4f;
      font-weight: 700;
    }
    .button-create-debit {
      background-color: #246fff;
      color: white;
      border-radius: 5px;
      font-weight: 700;
    }
  }
  .body-debit-filter {
    padding: 15px 0;
  }
  .body-debit-delete-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
  }
}
