.business-profile {
  &-title {
    h2 {
      font-size: 20px;
      font-weight: 700;
      color: #223b4f;
    }
  }
  &-content {
    margin-top: 20px;
  }
  h1 {
    color: #246fff;
    font-weight: 700;
    font-size: 18px;
  }
  div {
    font-size: 18px;
    line-height: 1.8;
    span {
      font-weight: 600;
    }
  }
}
