@mixin button-background($color) {
  background-color: $color !important;
  border-color: $color !important;
  color: white !important;
  border-radius: 5px !important;
  &:hover {
    background-color: lighten($color, 8%) !important;
  }
}
.body-import {
  // background-color: white;
  // padding: 16px;
  // margin: 15px 15px;
  // border-radius: 5px;
  .body-import-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 15px;
    .body-import-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .body-import-header-list-text {
      font-size: 20px;
      padding-right: 10px;
      color: #223b4f;
      font-weight: 700;
    }
  }
  .body-import-filter {
    padding: 15px 0;
  }
  .body-import-delete-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  .out-stock {
    background-color: #ffe298;
  }
  &-total-item {
    text-align: center;
    background-color: #246fff;
    border-radius: 10px;
    padding: 10px 0;
    h3 {
      color: white;
    }
    // &:hover {
    //   transform: translateY(-5px);
    //   transition: all 0.3s;
    //   box-shadow: 0px 8px 25px #b1ccff;
    // }
  }
}
